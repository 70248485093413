import TAGSORT from "../class/tagsort";

const [d] = [document];

const domReady = TPL => {
};

const itemTemplate = `
  <div class="rwd002-list-content is-list-12 lineup-list" :class="[ 'is-paged-' + paged ]">
    <div class="rwd002-list-content__inner">
      <transition-group tag="ul" class="rwd002-list-content__list" name="fade" mode="out-in">
        <li class="rwd002-list-content__item lineup-list__item" v-for="( article, ind ) in pagedArticles" :key="article.id" :class="[ 'is-item-' + ind ]">
          <a :href="article.href" class="rwd002-list-content__link">
            <figure class="rwd002-list-content__thumb"><img :src="article.thumb" :alt="article.title" width="100%" loading="lazy" class="rwd002-list-content__thumb-img"></figure>
            <div class="rwd002-list-content__body">
              <span class="cate" :class="'is-cat'+article.category.id" :data-href="article.category.href" v-if="article.category">{{ article.category.name }}</span>
              <h5 class="title">{{ article.title }}</h5>
              <p class="desc" v-if="article.desc.length">{{ article.desc.split('|')[0].trim() }}</p>
              <p class="price" v-if="/\|/.test(article.desc)">{{ getPrice(article.desc) }}</p>
              <div class="tags" v-if="article.keywords.length">
                <span class="tag" v-for="keyword in article.keywords.slice(0,4)">#{{keyword}}</span>
              </div>
            </div><!-- /.rwd002-list-content__body -->
          </a>
          <!-- /.rwd002-list-content__link -->
        </li>
        <!-- /.rwd002-list-content__item -->
      </transition-group>
      <!-- /.rwd002-list-content__list -->
    </div>
    <!-- /.rwd002-list-content__inner -->
  </div>
  <!-- /.rwd002-list-content -->`;

const relatedItem = `
  <div class="rwd002-related-content is-list-12" v-if="relatedArticles.length">
  <div class="rwd002-related-content__inner">
    <h3 class="lineup-list__title"><svg class="title" width="290" height="33"><use href="/assets/svg/sprite.symbol.svg#block-title_recomend"></use><title>RECOMMEND</title></svg><span>おすすめ商品</span></h3>
    <ul class="rwd002-related-content__list lineup-list">
      <li class="rwd002-related-content__item lineup-list__item" v-for="( article, ind ) in relatedArticles" :key="article.id" :class="[ 'is-item-' + ind ]">
        <a :href="article.href" class="rwd002-related-content__link">
          <figure class="rwd002-related-content__thumb"><img :src="article.thumb" :alt="article.title" width="100%" loading="lazy" class="rwd002-related-content__thumb-img"></figure>
          <div class="rwd002-related-content__body">
            <span class="cate" :class="'is-cat'+article.category.id" :data-href="article.category.href" v-if="article.category">{{ article.category.name }}</span>
            <h5 class="title">{{ article.title }}</h5>
            <p class="desc" v-if="article.desc.length">{{ article.desc.split('|')[0].trim() }}</p>
            <p class="price" v-if="/\|/.test(article.desc)">{{ getPrice(article.desc) }}</p>
          </div><!-- /.rwd002-related-content__body -->
        </a>
        <!-- /.rwd002-related-content__link -->
      </li>
      <!-- /.rwd002-related-content__item -->
    </ul>
    <!-- /.rwd002-related-content__list -->
  </div>
  <!-- /.rwd002-related-content__inner -->
  </div>
  <!-- /.rwd002-related-content -->`;

export default function LINEUP () {
  const Tagsort = new TAGSORT({
    list: {
      perpage: 12, // 一度に表示する記事数
      itemTemplate, // テンプレートの上書き（falseでデフォルト表示）
      addMethods: {
        getPrice (desc) {
          return desc.split('|')[1]
        }
      }, // 一覧用Vueオブジェクトのmethodプロパティ拡張
    },
    pagination: {
      el: '.rwd002-pagination', // paginationのVUEオブジェクト上書き
      template: false, // テンプレートの上書き（falseでデフォルト表示）
    }, 
    taglist: {
      container: '.rwd002-container', // taglistを表示する基準の要素
      disp_list: 'both', // head / foot / both(true) / append / prepend
      disp_detail: false, // head / foot / both(true) / append / prepend
      minimumTagAmount : 12, // タグの最低表示数
      hideUselessTags : 0, // 最低使用回数
      addAll: 'ALL', // カテゴリにALLを追加するか [ボタン名 / false]
      btnHtml: {
        open: `全てを表示<i class="fa-solid fa-angle-down fa-xs ms-1"></i>`,
        close: `一部のみ表示<i class="fa-solid fa-angle-up fa-xs ms-1"></i>`,
      }
    }, 
    related: {
      disp_detail: false,
      itemTemplate: relatedItem,
      sortType: 'tag', // or category
      amount: 4,
      addMethods: {
        getPrice (desc) {
          return desc.split('|')[1]
        }
      },
    },
  });

  Tagsort.render();

  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}