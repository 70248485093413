export default function GET_INSTAGRAM(area='body'){

  const $containers = $('[data-plugin="getInstagram"]',area)

  if( $containers.length == 0 ) return false
  const defarr = []
  $containers.each((i,obj) => {
    const d = new $.Deferred()
    defarr.push(d.promise())
    const defaultOpt = {
            amount: 20,
            prefix: 'block-sns',
            dispSize: 185
          },
          param = $.extend(true,defaultOpt,obj.dataset),
          getJSON = $.get('/assets/php/instagram.php',param,'json')
  
    getJSON.done((data)=> {
      let dataObj = JSON.parse(data||"null")
      let mediaObj = (dataObj.business_discovery)? dataObj.business_discovery.media.data: dataObj.media.data
      $(obj).empty()
      $.each( mediaObj , (i,v) => {
        let imgurl
        switch( v.media_type ){
          case 'IMAGE':
          case 'CAROUSEL_ALBUM': imgurl = v.media_url
            break
          case 'VIDEO': imgurl = v.thumbnail_url
            break
        }
        if( imgurl == undefined ) return
        $(`
          <figure class="${param.prefix}__item">
            <a href="${v.permalink}" target="_blank" style="background-image:url('${imgurl}')" class="is-${v.media_type.toLowerCase()}">
              <img src="${imgurl}" alt="${v.caption}" width="${param.dispSize}" />
            </a>
          </figure>
        `).appendTo(obj)
      })
      d.resolve(data)
    })

  })

  return $.when.apply($,defarr)
}